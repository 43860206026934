var charcount = 0;
function typeIt(target) {
	var humanize = Math.round(Math.random() * (200 - 30)) + 10;
	var target = $("#search_term");
	var txt = target.data('placeholder');
	if (txt) {
		var timeOut = setTimeout(function () {
			charcount++;
			var type = txt.substring(0, charcount);
			target.attr('placeholder', type + '|');

			if (charcount == txt.length) {
				target.attr('placeholder', target.attr('placeholder').slice(0, -1)) // remove the '|'
				clearTimeout(timeOut);
			}
			else{
				typeIt();
			}

		}, humanize);
	}
}

function imagePreview(input, previewElement) {

    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            previewElement.attr('style', 'background-image:url('+e.target.result+')');
        }

        reader.readAsDataURL(input.files[0]);
    }
}

$(function(){

	$('[data-toggle="tooltip"]').tooltip();

	var base_url = $('base').attr('href');

	$('input[name="is_professional"]').click(function(){
		if ($(this).is(':checked')) {
			$('.prospectus').slideDown();
		}else{
			$('.prospectus').slideUp();
		}
	});
	$("#home .question .card-content").matchHeight();
	$("#home .question").matchHeight();

	$('.mbc-facebook-bg').click(function(){
		if (!$('#rules').is(':checked')) {
			alert('Öncelikle katılım koşullarını kabul etmeniz gerekmektedir.');
			return false;
		}
	});

	typeIt($("#search_term"));

	$(".uvula").hover(function(){
		$(this).addClass("animated swing");
	},function(){
		$(this).removeClass("animated swing");
	});

	$('#search button').click(function() {
		var search_term = $('#search #search_term').val();

		if(search_term.length > 3){
			$.ajax({
				method: "GET",
				url: base_url+"/search/"+search_term,
				success: function(results) {
					$('.results ul').html('');
					if (results.length !== 0) {
						for (var i = 1; i <= results.length-1; i++) {
							$('.results ul').append('<li><a href="'+base_url+'/soru/'+results[i]['slug']+'">'+results[i]['title']+'</a></li>');
							$('.results').fadeIn();
						}
					}else{
						$('.results ul').append('<li>Üzgünüz. <b>'+search_term+'</b> ile ilgili sonuç bulunamadı...</li>');
						$('.results').fadeIn();
					}
				}
			});
		}
		ga('send', 'event', 'Search', 'Soru');
		return false;
	});

	var typingTimer;                //timer identifier
	var doneTypingInterval = 500;  //time in ms, 5 second for example

	$('#search #search_term').keyup(function(e) {
		$('.results').fadeOut();

		if (e.keyCode == 13) {
			$('#search button').trigger('click');
		}

	    clearTimeout(typingTimer);
	    typingTimer = setTimeout(function(){$('#search button').trigger('click');}, doneTypingInterval);

	});

	$('.share').click(function(){
		return false;
	});

	$('.btn-like i[data-meme-id]').click(function(){
		var t = $(this);
		var id = $(this).data('meme-id');
		var likeCountEl = t.parent().find('.count');

		$.ajax({
			type:'post',
			url:base_url+'/yarisma/caps/'+id+'/like',
			data:{'id':id, '_token': $('input[name="_token"]').val()},
			success:function(data){
				dat = JSON.parse(data);
				if (dat.status == "liked") {
					likeCountEl.html(Number.parseInt(likeCountEl.html())+1);
					t.removeClass('fa-heart-o').addClass('fa-heart');
				}else{
					likeCountEl.html(Number.parseInt(likeCountEl.html())-1);
					t.removeClass('fa-heart').addClass('fa-heart-o');
				}
			}
		});
		return false;
	});
	$('.btn-like i[data-redirect="login"]').click(function(){
		swal({
			title: "Beğenmek için giriş yapmalısınız",
			type: "warning",
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Giriş Yap",
			closeOnConfirm: false
		}, function(){
			window.location = base_url+'/auth/login'
		});
		return false;
	});

	var questionModule = {
		icon:$('.usye_icon'),
		HTMLdata:'',
		questionEndpoint:'',
		emailEndpoint:'',
		validateEmail:function(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		ajax:function ajaxForm(form,endpoint,callback){
			form.find('input').bind('keypress', function(e) {
				if (e.which == 13) {
					e.stopPropagation();
				}
			});
			form.find('a').click(function(){
				if ($.trim(form.serializeArray()[0]['value']) != "") {
					if (form.serializeArray()[0]['name'] == "email") {
						if (questionModule.validateEmail(form.serializeArray()[0]['value'])) {
							$.ajax({
								url:endpoint,
								method:'POST',
								data:{_token: App.CsrfToken,value:form.serializeArray()[0]['value']},
								dataType:'JSON',
								success:function(data){
									callback(data.status,data.message,data.title);
								}
							});
						}
					}else{
						$.ajax({
							url:endpoint,
							method:'POST',
							data:{_token: App.CsrfToken,value:form.serializeArray()[0]['value']},
							dataType:'JSON',
							success:function(data){
								callback(data.status,data.message,data.title);
							}
						});
					}

				}
				return false;
			});
		},
		iconClick:function(){
			questionModule.questionEndpoint = questionModule.icon.data('endpoint');
			$.get(questionModule.questionEndpoint, function(data) {
				questionModule.HTMLdata = data;
			}).done(function() {
				if (questionModule.HTMLdata.status) {
					swal({
						title:'',
						type:'error',
						text:questionModule.HTMLdata.message,
						confirmButtonColor: "#888",
						confirmButtonText: "Peki :(",
					});
				}else{
					swal({
						title:'',
						text: questionModule.HTMLdata,
						showConfirmButton: false,
						html: true,
					});
					setTimeout(function(){
						$('.cancel').click(function(){
							swal.close();
						});
						questionModule.ajax($('#question-form'), questionModule.questionEndpoint, function(status,message,title){
							if (status == "success") {
								questionModule.emailEndpoint = questionModule.icon.data('emailendpoint');
								$.get(questionModule.emailEndpoint, function(data) {
									questionModule.HTMLdata = data;
								}).done(function(data) {
									if (data != "") {
										swal({
											title:'',
											text: data,
											showConfirmButton: false,
											html: true,
										});
									}
								});
								setTimeout(function(){
									questionModule.ajax($('#question-form'), questionModule.emailEndpoint, function(status,message,title){
										if (status == "success") {
											swal({
												title:'',
												type:'success',
												text:'Katılımınız için teşekkürler',
												confirmButtonColor: "#3b435d",
												confirmButtonText: "Rica Ederim",
											});
										}else{
											swal({
												title:'',
												type:'warning',
												text:'Bu mail adresi ile daha önce yarışmaya katıldığınız için tekrar katılamıyorsunuz.',
												confirmButtonColor: "#888",
												confirmButtonText: "Peki :(",
											});
										}
									});
								},1000);
							}else if(status == "failed"){
								swal({
									title:title,
									type:'warning',
									text:message,
									confirmButtonColor: "#888",
									confirmButtonText: "Peki :(",
								});
							}
						});
					},500);
				}
			});
		}
	}

	questionModule.icon.click(function(){
		questionModule.iconClick();
	});

});